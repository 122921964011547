<!-- 计划 -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>演出计划管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="addDialogOpen"
            >添加演出计划</el-button
          >
        </el-col>
        <el-col style="float: right" :span="4">
          <el-button type="danger" @click="deleteDialogVisible = true"
            >清理过期演出计划</el-button
          >
        </el-col>
      </el-row>

      <!-- 演出计划列表 -->
      <el-table :data="scheduleList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="电影名称" prop="放映影片.名称"></el-table-column>
        <el-table-column label="影厅名称" prop="所属影厅.名称"></el-table-column>
        <el-table-column label="开始时间" prop="开始时间"></el-table-column>
        <el-table-column label="结束时间" prop="结束时间"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template v-slot:default="scope">
            <!-- {{scope.row}} -->
            <el-tooltip
              class="item"
              effect="dark"
              content="查看影片信息"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-aim"
                size="small"
                @click="showSessionInfo(scope.row.ID)"
              ></el-button>
            </el-tooltip>

            <!-- 修改按钮 -->
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑价格信息"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                @click="showEditDialog(scope.row.ID)"
              ></el-button>
            </el-tooltip>

            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              @click="removeMovieById(scope.row.ID)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加演出计划的对话框 -->
    <el-dialog
      title="添加演出计划"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="电影名称" prop="影片id">
          <el-select
            style="width: 90%"
            v-model="addForm.影片id"
            placeholder="请选择影片名称"
          >
            <el-option
              v-for="(item,index) in movies"
              :key="index"
              :label="item.名称"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="演出厅名称" prop="影厅id">
          <el-select
            style="width: 90%"
            v-model="addForm.影厅id"
            placeholder="请选择放映的影厅"
          >
            <el-option
              v-for="(item,index) in studios"
              :key="index"
              :label="item.名称"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上映日期" prop="上映日期">
          <el-date-picker
            style="width: 90%"
            v-model="addForm.上映日期"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="放映天数" prop="放映天数">
          <el-input v-model="addForm.放映天数" type='number'></el-input>
        </el-form-item>
        <el-form-item label="放映时间" prop="放映时间">
          <el-time-picker
            v-model="addForm.放映时间"
            value-format="HH:mm"
            style="width: 90%"
            placeholder="选择时间"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSchedule()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除演出计划确定框 -->
    <el-dialog title="提示" :visible.sync="deleteDialogVisible" width="30%">
      <span>确定要删除所有的过期演出计划吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="deleteOverduePlan">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看电影详细信息 -->
    <el-dialog title="电影详情页" :visible.sync="showInfoDialogVisible" width="50%">
      <div class="exhibit">
        <div>
          <img :src="showInfo.放映影片.封面照片" alt="封面照片" width="100%" height="100%"/>
        </div>
        <div>
          <span class="infodes">电影名称：</span>
          <span>{{ showInfo.放映影片.名称 }}</span>
        </div>
        <div>
          <span class="infodes">演出厅：</span>
          <span>{{ showInfo.所属影厅.名称 }}</span>
        </div>
        <div>
          <span class="infodes">演出时间：</span>
          <span>{{ showInfo.开始时间 }} - {{ showInfo.结束时间 }}</span>
        </div>

      <!-- 演出计划列表 -->
      <el-table :data="showInfo.场次规格" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="区域名" prop="区域名"></el-table-column>
        <el-table-column label="平台价格" prop="区域价格"></el-table-column>
        <el-table-column label="线下价格" prop="原价"></el-table-column>
        <el-table-column label="剩余座位数量" prop="剩余可定数量"></el-table-column>
      </el-table>

      </div>
    </el-dialog>


    <!-- 修改电影的对话框 -->
    <el-dialog
      title="修改场次规格"
      :visible.sync="showEditDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区 -->
      <el-form
        :model="editForm"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="电影名称">
          <el-input v-model="editForm.放映影片.名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="演出厅">
          <el-input v-model="editForm.所属影厅.名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="演出开始时间">
          <el-input v-model="editForm.开始时间" disabled></el-input>
        </el-form-item>
        <el-form-item label="是否统一价格" prop="delivery">
          <el-switch v-model="editIsUnifyAll" active-text="以此次编辑为模板统一设置该演出厅之后所有同一影片的价格"></el-switch>
        </el-form-item>

        <!-- 规格编辑区 -->
        <el-form-item
          v-for="(sku, index) in editForm.场次规格"
          :label="sku.区域名 + '区'"
          :key="index"
          :prop="'场次规格.' + index + '.区域价格'"
          :rules="[{
            required: true, message: '价格不能为空', trigger: 'blur'
          },
          {
            type: 'number', message: '请输入数字格式', trigger: 'blur', transform(value) { return Number(value); }
          },
          ]"
        >
          <el-input v-model="sku.区域价格" placeholder="请输入区域平台价格">
            <template slot="prepend">平台价格</template>
          </el-input>
          <el-input v-model="sku.原价" placeholder="请输入区域线下价格" style="margin-top: 10px">
            <template slot="prepend">线下价格</template>
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditDialogVisible = false, editIsUnifyAll = false">取 消</el-button>
        <el-button type="primary" @click="changeSku()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Qs from "qs";

export default {
  name: "plan",
  data() {
    return {
      queryInfo: {
        // 当前的页数
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      scheduleList: [],
      addDialogVisible: false,
      deleteDialogVisible: false,
      showEditDialogVisible: false,
      showInfoDialogVisible: false,
      showInfo: {
        放映影片: {},
        所属影厅: {},
        场次规格: []
      },
      addForm: {
        影片id: "",
        影厅id: "",
        上映日期: "",
        放映时间: "",
        放映天数: "",
      },
      addFormRules: {
        影片id: [{ required: true, message: "请选择影片", trigger: "change" }],
        影厅id: [{ required: true, message: "请选择演出厅", trigger: "change" }],
        上映日期: [{ required: true, message: '请选择上映日期', trigger: 'change' }],
        放映时间: [{ required: true, message: '请选择放映时间', trigger: 'change' }],
        放映天数: [{ required: true, message: "请输入放映天数", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            },
          }
        ]
      },
      editForm: {
        放映影片: {},
        所属影厅: {},
        场次规格: []
      },
      editIsUnifyAll: false,
      movies: [],
      studios: [],
    };
  },
  created() {
    this.getScheduleList();
  },
  computed: {
  },
  methods: {
    async getScheduleList() {
      const data = await this.$axios.get(
        "/manager/sessionList",
        {
          params: this.queryInfo,
        }
      );
      console.log(data)
      if (data.code == 200) {
        this.scheduleList = data.data;
        this.total = data.total;
        console.log(this.total);
      } else {
        this.$message.warning(res.msg);
      }
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getScheduleList();
    },
    //删除计划
    removeMovieById(id) {
      // console.log(id);
      this.$confirm("此操作将永久删除该演出计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          this.$axios({
            method: "post",
            url: "/manager/deleteSession",
            data: {
              id
            }
          })
            .then((res) => {
              console.log(res);
              loading.close();
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getScheduleList();
              } else {
                this.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              console.log(err)

              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取电影列表
    getMovies() {
      this.$axios({
        url: "/manager/movieList",
      })
        .then((res) => {
          console.log(res.data);
          this.movies = res.data;
        })
        .catch((err) => err);
    },
    //获取演出厅列表
    getStudios() {
      this.$axios({
        url: "/manager/hallList",
        params: {
          status: '1'
        }
      })
        .then((res) => {
          console.log(res.data);
          this.studios = res.data;
        })
        .catch((err) => err);
    },
    //关闭添加对话框
    addDialogClosed() {
      (this.addForm.mValue = ""),
      (this.addForm.sValue = ""),
      (this.addForm.dValue = ""),
      (this.addForm.tValue = ""),
      this.$refs.addFormRef.resetFields();
    },
    //打开添加对话框
    addDialogOpen() {
      this.addDialogVisible = true;
      this.getMovies();
      this.getStudios();
    },
    //通过YY-MM-DD hh:mm:ss获取时间戳
    getTimestamp(stringTime) {
      // var stringTime = "2014-07-10 10:21:12";
      var timestamp2 = stringTime.replace(/-/g, "/"); //为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23'
      timestamp2 = Date.parse(timestamp2); //返回'2018-9-12 9:11:23'的时间戳
      // console.log(stringTime + "的时间戳为：" + timestamp2);
      return timestamp2;
    },
    //确认添加演出计划
    addSchedule() {
      console.log(this.$refs.addFormRef)
      this.$refs.addFormRef.validate((valid) => {
        console.log(valid);
        if (!valid) return;
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$axios({
          method: "post",
          url: "/manager/addSession",
          data: Qs.stringify(this.addForm),
        })
          .then((res) => {
            loading.close();
            if (res.code == 200) {
              this.$message.success("添加成功");
              // 隐藏对话框
              this.addDialogVisible = false;
              // 刷新列表
              this.getScheduleList();
            }else{
              this.$message.warning(res.msg);
              // 隐藏对话框
              this.addDialogVisible = false;
            }
          })
          .catch((err) => {
            console.log(err)
            loading.close();
          });
      });
    },
    //当前时间时分秒
    getNowDate() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      var mon = myDate.getMonth() + 1; //获取当前月
      var date = myDate.getDate(); //获取当前日
      var hours = myDate.getHours(); //获取当前小时
      var minutes = myDate.getMinutes(); //获取当前分钟
      var seconds = myDate.getSeconds(); //获取当前秒
      var now =
        year +
        "-" +
        mon +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return now;
    },
    //系统时间时间戳
    getNowTimestamp() {
      return new Date(this.getNowDate()).getTime();
    },
    //删除过期的演出计划
    deleteOverduePlan() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios({
        method: "put",
        url: "/manager/clearSession",
      })
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            this.$message.success("删除成功");
            // 隐藏对话框
            this.deleteDialogVisible = false;
            // 刷新列表
            this.getScheduleList();
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          loading.close();
        });
    },
    showEditDialog(id) {
      console.log(id)
      this.showEditDialogVisible = true;
      this.$axios({
        method: "get",
        url: "/manager/sessionDetail/?id=" + id,
      })
        .then((res) => {
          // console.log(res.data);
          this.editForm = res.data;
        })
        .catch((err) => console.log(err));
    },
    //监听对话框关闭 清空
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
      this.editIsUnifyAll = false;
    },
    changeSku() {
      this.$refs.editFormRef.validate((valid) => {
        // console.log(valid);
        if (!valid) return;
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$axios({
          method: "post",
          url: "/manager/editSessionSku",
          data: {
            sessionId: this.editForm.ID,
            hallId: this.editForm.所属影厅ID,
            isUnifyAll: this.editIsUnifyAll,
            sessionSkuList: this.editForm.场次规格
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            loading.close();
            if (res.code == 200) {
              //提示修改成功
              this.$message.success("修改规格信息成功");
              // 隐藏对话框
              this.showEditDialogVisible = false;
              // 刷新列表
              this.getScheduleList();
            } else {
              this.$message.warning(res.msg)
            }
          })
          .catch((err) => {
            loading.close();
            console.log(err.message);
          });
      });
    },
    showSessionInfo(id) {
      // console.log(id)
      this.showInfoDialogVisible = true;
      this.$axios({
        method: "get",
        url: "/manager/sessionDetail/?id=" + id,
      })
        .then((res) => {
          // console.log(res.data);
          this.showInfo = res.data;
          this.showInfo.放映影片.封面照片 = JSON.parse(this.showInfo.放映影片.封面照片)[0].url
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
</style>
